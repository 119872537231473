
.Main {
  position:relative;
  overflow:hidden;
}

.Name {
  font-size: 90px;
  font-family: monospace;
  text-align: center;
  position: relative;

  background: 50% 100% / 50% 50% no-repeat radial-gradient(ellipse at bottom, #61dafb, transparent, transparent);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: display 1500ms ease-in-out forwards 0ms,
  radiate 4000ms linear infinite 1500ms;
  z-index: 9999;
}
  @keyframes display {
    80%{
      letter-spacing: 50px;
    }
    100%{
      background-size:150%,150%;
    }
    
  }
  @keyframes radiate{
    10%{
      text-shadow: 0 0 1px #fff
    }
  }

.Grid{
  display:flex;
    justify-content:center;
  align-items:center;
  margin-top:200px;
}
.MuiCard-root{
  height:100%;
}
body {
  background-color: #444746ff
}

























.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

